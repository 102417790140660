@use "sass:math";
@import 'vars.scss';
body {
    font-family: lato,sans-serif;
    font-size: 16px;
    color: #2C2C2C;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
h1,h2,h3 {
    font-family: degular, sans-serif;
    font-weight: normal;
}
h1 {
    font-size: 60px;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    font-family: degular, sans-serif;
    color:#2C2C2C;
    font-size:50px;
}
.primary {
    color:$primary_colour;
}
.secondary {
    color:$secondary_colour;
}

.button {
    @include gradient-button;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:2em;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:2em;
    margin-bottom:10px;
}
img.negative-bottom-margin {
    margin-bottom:-20%;
}
blockquote {
    padding:1em;
    margin:-1em;
    margin-top:15%;
    margin-bottom:1em;
    max-width: 450px;
    border-radius: 30px;
    position: relative;
    @include drop-shadow;
    background:white;
    color:#2C2C2C;
}

blockquote.alternate {
    padding-top:100px;
    max-width: 450px;
    margin-left:0;
    padding-left:1em;
    position: relative;
    &::after {
        content:"";
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        width:0;        
        border-left: 1px solid $primary_colour;
    }
}
.divider {
    width:60px;
    margin:20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    &::before{
        content:"";
        border-bottom: 1px solid $primary_colour;
        position: absolute;
        top:50%;
        left:0;
        right:0;
    }
}
.turn-and-scale {
    @include turn-and-scale;
}
.narrow {
    max-width: math.div($body_width, 2);
}
.narrow-center {
    max-width: math.div($body_width, 2);
    margin: 0 auto;
}
ul.lined-list {
    @include lined-list(red);
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1200px) {
    .highlight {
        font-size:5vw;
    }
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px auto;
        display: block;
    }
}
@media only screen and (max-width: 400px){
    h1 {
        font-size: 45px;
    }
}